import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { MinimalApp } from "./renderer/MinimalApp";
import { theme } from "./theme/theme";

const cacheNoPrefixer = createCache({
  key: "noprefixer",
  ...(import.meta.env.DEV && { stylisPlugins: [] }),
});

export const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <CacheProvider value={cacheNoPrefixer}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <MinimalApp />
        </QueryClientProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
